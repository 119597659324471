/**
 * Author : Elia Contini - elia.contini.page
 * Created: 21 Mar 2020
 *
 * Home page
 */

import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = ({ location }) => (
   <Layout>
      <SEO location={location} title="Elia Contini personal website" />
      <h1>
         UX engineering,
         <br />
         Front-end Web architecture.
      </h1>
      <p>
         Hello! I am Elia, Sardinian UX engineer and Front-end Web architect
         based in Switzerland.
      </p>
      <p>
         I have a background both in technology and communication and I am
         specialised in the Web platform. My work is focused in the design and
         development of Web applications and websites that improve the quality
         of life of the people.
      </p>

      <p>
         I coded my first website in 1999 and, since the beginning of my career,
         I promote inclusion and advocate about an accessible and usable Web by
         using{" "}
         <a
            className="link--external"
            href="https://www.w3.org/"
            title="External link"
         >
            W3C
         </a>{" "}
         standards.
      </p>

      <p>
         Founder of{" "}
         <a
            className="link--external"
            href="http://www.frontenders.ch/"
            title="External link"
         >
            FrontEnders Ticino
         </a>{" "}
         and co-founder of{" "}
         <a
            className="link--external"
            href="https://twitter.com/uxcampit"
            title="External link"
         >
            UX Camp Italia
         </a>
         .
      </p>

      <p>
         In my spare time I'm a marathoner chasing his{" "}
         <a
            className="link--external"
            href="https://www.worldmarathonmajors.com/"
            title="External link"
         >
            Six Stars Medal
         </a>
         . Trekker and traveller{" "}
         <a
            className="link--external"
            href="https://www.in-love-with.earth/"
            title="External link"
         >
            In Love With Earth
         </a>
         .
      </p>
   </Layout>
);

export default IndexPage;
